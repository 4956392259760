<div class="container-fluid d-flex about-container">
    <div class="row pl-5-lg pr-5-lg d-flex mt-5 ml-5-lg animated about-box fadeIn" style="flex-direction: row-reverse;">
        <div class="col-lg-4 pb-3 mb-3 photo animated fadeIn " style="height:auto;">
            <img style="height: 400px; border-radius: 20px;" src="../../assets/img/aboutbw.jpg" alt="">
        </div>
        <div class="col-lg-8 p-3 box-background">
            <span style="font-size: 1.1rem; color: black;">
                My name is André, I am {{age}} years old and I come from Aveiro (Portugal), currently living in
                Barcelona.
                <p></p>
                I am a friendly and empathic person passionate about Music, Bicycles, Food and Culture. From an early
                age I started to ride BMX, where
                I had the pleasure to meet like-minded people that taught me strong community values and
                love for exploration and experimentation. Restless by nature, I am a person who likes to be involved in
                many different projects in the fields of music and bike riding, helping and contributing in all possible
                ways and reinforcing social and friendship ties by participating in these projects.
                <p></p>
                2008 was the year I graduated from Mechanical Engineering and after that I have specialized myself in Lighting Design and
                Engineering. I have been professionally related to this field during the last 10 years of my
                professional
                career,
                working for different companies and projects around Europe.
                <p></p>
                My love for music took a step further in 2016 when I had the chance to join my first band as a synth
                player, followed by two more bands that were the perfect way to channel my artistic needs.
                <p></p>
                In 2019 I felt a professional restlessness that made me think about my working situation. I had a steady
                job where I was comfortable at and enjoyed it, but I felt the need of learning something new and fresh.
                I remembered
                from University that I didn't like programming, but I also recall one professor of mine telling me
                "once you learn how to code, it gets really addictive". I also thought that, as the world is changing
                at a really fast pace
                and programming might be really handy in the short future, maybe it was the time to give coding a second
                chance. Moreover, Frontend webdevelopment sounded like a really interesting field. A field where I could merge my
                tech-savvy skills with my creativity and my willingness to create new projects and ideas.
                <p></p>
                Last year I made an important step in my life: I decided to make a shift on my professional path and
                go back to school.
                In January 2020 I enrolled in the Frontend Web Development course from the IT Academy (Barcelona Activa), where I could
                learn all the basics of frontend programming such as
                HTML, CSS and Javascript, jQuery and AJAX, but also modern frameworks such as ReactJS and Angular.
                During 2020 I also got back to German classes, where I finished the B1 certification, and I could spend
                more time working on my music projects, learning, experimenting and composing new songs. Last but not least, during this period I also had the chance to record one LP and
                one EP.
                <p></p>
            </span>
        </div>
        <div class="justify-content-end pt-5 text-right mr-5">
            <button class="btn btn-outline btn-light" [routerLink]="['/home']"
                routerLinkActive="router-link-active">Back to
                Home</button>
        </div>
    </div>
</div>
<div class="container-fluid">
    <h1 class="pl-5 pt-2">Music</h1>
    <div class="container-fluid p-5 m-3">
        <div class="row d-flex justify-content-center animated fadeIn">
            <div class="col-lg-4 pb-4 mb-4 text-align-center bands" *ngFor="let band of music; let i=index">
                <div class=""></div>
                <h2>{{band.band}}</h2>
                <img style="border-radius: 10px;" src={{band.img[0]}} (click)="goToBand(i)" alt="">
            </div>
        </div>
    </div>
    <div class="justify-content-end pt-5 text-right mr-5">
        <button class="btn btn-outline btn-light" [routerLink]="['/home']" routerLinkActive="router-link-active">Back to
            Home</button>
    </div>
</div>
<div class="container-fluid d-flex d-block contact-container">
    <div class="container">
        <p></p>
        <h2 class="text-center mb-5">Hi! If you'd like to contact me, send me a message or give me a call!</h2>
        <div class="row">
            <div class="col-md-6 offset-md-3">
                <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">
                    <div class="form-group">
                        <label for="Email">Email</label>
                        <input type="email" class="form-control" name="Email" aria-describedby="emailHelp"
                            placeholder="Enter email" formControlName="Email">
                        <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone
                            else.</small>
                    </div>
                    <div class="form-group">
                        <label for="fullname">Full Name</label>
                        <input type="text" class="form-control" name="Fullname" placeholder="Full Name"
                            formControlName="Fullname">
                    </div>
                    <div class="form-group">
                        <label for="comment">Message</label>
                        <textarea class="form-control" formControlName="Comment" name="Comment">
        </textarea>
                    </div>
                    <button type="submit" class="btn btn-secondary" [disabled]="!FormData.valid">Submit</button>
                </form>
                <div class="pt-3">
                    <p></p>
                    <mat-icon svgIcon="Mobile">p</mat-icon><span>  +34 693 460 337</span>
                    <p></p>
                    <mat-icon svgIcon="Email">p</mat-icon><span>&nbsp; andre4130@gmail.com</span>

                </div>
            </div>
        </div>

    </div>
</div>   
 <div class="justify-content-end pt-5 text-right mr-5">
    <button class="btn btn-outline btn-light" [routerLink]="['/home']" routerLinkActive="router-link-active">Back to
        Home</button>
</div>
<div class="container home">
    <div class="row d-flex justify-content-center animated fadeIn">
        <div class="col-lg-4 pb-4 skill-content" *ngFor="let skill of skills; let i = index">
            <div class="skill {{skill.class}}">
                    <!-- <div class=""> -->
                        <div class="skill d-flex align-items-center justify-content-center" *ngIf="skill.type=='Music'" (click)="goToBands()">
                            <h1>{{skill.type}}</h1>
                        </div>
                        <div class="skill d-flex align-items-center justify-content-center" *ngIf="skill.type=='About'" (click)="goToAbout()">
                            <h1>{{skill.type}}</h1>
                        </div>
                        <div class="skill d-flex align-items-center justify-content-center" *ngIf="skill.type=='Engineering & Design'||skill.type=='Frontend Development'" (click)="goToSkill(i)">
                            <h1>{{skill.type}}</h1>
                        </div>
                        <div class="skill d-flex align-items-center justify-content-center" *ngIf="skill.type=='CV'" (click)="goToCV()">
                            <h1>{{skill.type}}</h1>
                        </div>
                        <div class="skill d-flex align-items-center justify-content-center" *ngIf="skill.type=='Contact'" (click)="goToContact()">
                            <h1>{{skill.type}}</h1>
                        </div>   
            </div>
        </div>
    </div>
</div>
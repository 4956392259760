<div class="container-fluid pl-2 pr-2 pt-2 pl-5 pr-5 animated fadeIn">
    <!-- Main Header of the Section -->
    <div class="">
        <h1>Curriculum Vitae</h1>
    </div>
    <div class="skill.type row">
        <div class="professional pt-3 col-lg-6">
            <div class="col-12">
                <h3 style="color: white">Professional Experience</h3>
            </div>
            <div class="col-md-12 pt-3">
                <div class="p-5 professional" style="background-color: rgba(0, 0, 0, 0.65); border-radius: 20px;">
                    <div class="pl-3 pr-3 pt-3">
                        <p style="font-size: 1rem;">{{skills[0].experienceDate5}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[0].experience5}}</p>
                        </div>
                        <div class="text-right">
                            <a href="https://www.seat.es/" target="_blank"><small>{{skills[0].experienceLocation5}}</small></a>
                        </div>
                        <hr>
                    </div>
                    <div class="pl-3 pr-3 background-exp">
                        <p style="font-size: 1rem;">{{skills[0].experienceDate1}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[0].experience1}}</p>
                        </div>
                        <div class="text-right">
                            <a href="https://www.seat.es/" target="_blank"><small>{{skills[0].experienceLocation1}}</small></a>
                        </div>
                        <hr>
                    </div>
                    <div class="pl-3 pt-3 background-exp">
                        <p style="font-size: 1rem;">{{skills[0].experienceDate2}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[0].experience2}}</p>
                        </div>
                        <div class="text-right">
                            <a href="https://www.zoopa.es/" target="_blank"><small>{{skills[0].experienceLocation2}}</small></a>
                        </div>
                        <hr>
                    </div>
                    <div class="pl-3 pt-3 background-exp">
                        <p style="font-size: 1rem;">{{skills[0].experienceDate3}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[0].experience3}}</p>
                        </div>
                        <div class="text-right">
                            <a
                                href="https://www.studioroosegaarde.net/stories" target="_blank"><small>{{skills[0].experienceLocation3}}</small></a>
                        </div>
                        <hr>
                    </div>
                    <div class="pl-3 pt-3 background-exp">
                        <p style="font-size: 1rem;">{{skills[0].experienceDate4}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[0].experience4}}</p>
                        </div>
                        <div class="text-right">
                            <a
                                href="https://giip.upc.edu/es/formacion/postgrado-eltica" target="_blank"><small>{{skills[0].experienceLocation4}}</small></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="education pt-3 col-lg-6">
            <div class="col-12">
                <h3 style="color: black">Education</h3>
            </div>
            <div class="col-md-12 pt-3">
                <div class="p-5 education" style="background-color: rgba(255, 255, 255, 0.65); border-radius: 20px;">
                    <div class="pl-3 pr-3 pt-3">
                        <p style="font-size: 1rem;">{{skills[1].educationDate1}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[1].education1}}</p>
                        </div>
                        <div class="text-right">
                            <a
                                href="https://cibernarium.barcelonactiva.cat/"  target="_blank"><small style="color: black">{{skills[1].educationLocation1}}</small></a>
                        </div>
                        <hr>
                    </div>
                    <div class="pl-3 pr-3 pt-3">
                        <p style="font-size: 1rem;">{{skills[0].educationDate0}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[0].education0}}</p>
                        </div>
                        <div class="text-right">
                            <a
                                href="https://www.seat.es/"  target="_blank"><small style="color: black">{{skills[0].educationLocation0}}</small></a>
                        </div>
                        <hr>
                    </div>
                    <div class="pl-3 pr-3 pt-3">
                        <p style="font-size: 1rem;">{{skills[0].educationDate1}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[0].education1}}</p>
                        </div>
                        <div class="text-right">
                            <a href="https://www.fundaciocim.org/" target="_blank"><small style="color: black">{{skills[0].educationLocation1}}</small></a>
                        </div>
                        <hr>
                    </div>
                    <div class="pl-3 pr-3 pt-3">
                        <p style="font-size: 1rem;">{{skills[0].educationDate2}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[0].education2}}</p>
                        </div>
                        <div class="text-right">
                            <a href="https://www.fundaciocim.org/" target="_blank"><small style="color: black">{{skills[0].educationLocation1}}</small></a>
                        </div>
                        <hr>
                    </div>
                    <div class="pl-3 pr-3 pt-3">
                        <p style="font-size: 1rem;">{{skills[0].educationDate3}}</p>
                        <div class="text-right pt-1 experience">
                            <p class="experience">{{skills[0].education3}}</p>
                        </div>
                        <div class="text-right">
                            <a href="https://www.ua.pt/" target="_blank"><small style="color: black">{{skills[0].educationLocation3}}</small></a>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid">
        <div class="">
            <h3>Languages</h3>
        </div>
        <div>
            <div class="row skill.type p-3">
                <div class="col-lg-2 text-center justify-content-center m-3" *ngFor="let language of languages">
                    <h5>{{language.lang}}</h5>
                    <circle-progress class="circle-bar" [percent]=language.progress [outerStrokeWidth]="4"
                        [innerStrokeWidth]="4" [outerStrokeColor]="'#333333'" [innerStrokeColor]="'#ffffff'"
                        [space]="-5" [animation]="true" [animationDuration]="1500" [showTitle]="true"
                        [showSubtitle]="false" [showUnits]="false" [titleFontSize]="15" [backgroundColor]="'#808080'"
                        [backgroundOpacity]="'0.8'" [backgroundPadding]="'-8'" [backgroundStroke]="'#D50283'"
                        [lazy]="true" [title]=language.level [renderOnClick]="false" [titleColor]="'#ffffff'">
                    </circle-progress>
                </div>
            </div>
            <div class="pt-3">
                <h3>Coding Skills</h3>
                <div class="row skill.type p-3">
                    <div class="col-lg-2 text-center justify-content-center mt-3 mb-3" *ngFor="let code of codes">
                        <h5>{{code.type}}</h5>
                        <circle-progress class="circle-bar" [percent]=code.progress [outerStrokeWidth]="4"
                            [innerStrokeWidth]="4" [outerStrokeColor]="'#333333'" [innerStrokeColor]="'#ffffff'"
                            [space]="-5" [animation]="true" [animationDuration]="1500" [showTitle]="true"
                            [showSubtitle]="false" [showBackground]="true" [showUnits]="false" [titleFontSize]="15"
                            [backgroundColor]="'#808080'" [backgroundOpacity]="'0.8'" [backgroundPadding]="'-8'"
                            [backgroundStroke]="'#D50283'" [lazy]="true" [renderOnClick]="false"
                            [titleColor]="'#ffffff'" [imageSrc]=code.imgsrc [showImage]="true" [imageHeight]="50"
                            [imageWidth]="50"></circle-progress>
                    </div>
                </div>
            </div>
            <div class="pt-3">
                <h3>Engineering & Design Skills</h3>
                <div class="row skill.type p-3">
                    <div class="col-lg-2 text-center justify-content-center m-3" *ngFor="let eng of engineering">
                        <h5>{{eng.type}}</h5>
                        <circle-progress class="circle-bar" [percent]=eng.progress [outerStrokeWidth]="4"
                            [innerStrokeWidth]="4" [outerStrokeColor]="'#333333'" [innerStrokeColor]="'#ffffff'"
                            [space]="-5" [animation]="true" [animationDuration]="1500" [showTitle]="true"
                            [showSubtitle]="false" [showBackground]="true" [showUnits]="false" [titleFontSize]="15"
                            [backgroundColor]="'#808080'" [backgroundOpacity]="'0.8'" [backgroundPadding]="'-8'"
                            [backgroundStroke]="'#D50283'" [lazy]="true" [renderOnClick]="false"
                            [titleColor]="'#ffffff'" [imageSrc]=eng.imgsrc [showImage]="true" [imageHeight]="50"
                            [imageWidth]="50"></circle-progress>
                    </div>
                </div>
            </div>
            <div>
                <h3>Patents</h3>
                <div class="text-left p-3 box-background">
                    <p></p>
                    <span>While working at SEAT, I had the chance to develop new ideas and concepts that ended up being
                        patented for industrial protection
                        <br>
                        Here you can find a list of the patents I participated in:
                    </span>
                    <p></p>
                    <ul>
                        <li>Proximity Light for the Exterior of a Vehicle (ES2551513A1) - 2015</li>
                        <li>Method and system to control an interior lighting of a vehicle (ES2607124A1) - 2016</li>
                        <li>Lighting Device for an Interior Panel of a Vehicle (EP3184363A1) - 2017</li>
                        <li>Illuminated Front Logo of a Vehicle (ES2769903A1) - 2018</li>
                    </ul>
                    <!-- <button type="button" class="btn btn-dark" (click)=downloadPatent()>Download Patents</button> -->
                </div>
            </div>
            <br>
            <p></p>
            <div class="">
                <h3>Curriculum Vitae</h3>
                <div class="pl-3 pb-3">
                    <span>Below you can download my one-page CV in PDF.
                    </span>
                    <p></p>
                    <button type="button" class="btn btn-dark" (click)=downloadPdf()>Download CV</button>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Div for Coding  -->
<!-- <div class="skill.type row" *ngIf="skill.type=='Front End Development'">
        <div class="col-md-6 pt-3">
            <div class="pl-5">
                <h4 style="color: black">Professional Experience</h4>
                <div class="pl-3 pr-3 pt-3">
                    <p style="font-size: 1rem;">{{skill.experienceDate1}}</p>
                    <div class="text-right pt-1 experience">
                        <p class="experience">{{skill.experience1}}</p>
                    </div>
                    <div class="text-right">
                        <small>{{skill.experienceLocation1}}</small>
                    </div>
                    <hr>
                </div>
            </div>
        </div>
    </div> -->


<!-- Div for Music -->

<!-- <div class="skill.type row" *ngIf="skill.type=='Music'">
        <div class="col-md-6">
            <div class="pl-5">
                <h4>Education</h4>
                <br>
                <h5>{{skill.experienceDate1}}</h5>
                <br>
                <p>{{skill.experience1}}</p>
                <div>
                    {{skill.education1}}
                </div>
                <div *ngIf="skill.type=='Music'">
                    <a href="{{skill.links[0]}}" target="_blank">vuelve zinc</a>
                    <p></p>
                    <a href="{{skill.links[1]}}" target="_blank">Unfollowers</a>
                    <p></p>
                    <a href="{{skill.links[2]}}" target="_blank">Mayer-Sánchez</a>
                </div>
            </div>
        </div>
    </div> -->
<div class="justify-content-end pt-5 text-right pr-5">
    <button class="btn btn-outline btn-light" [routerLink]="['/home']" routerLinkActive="router-link-active">Back to
        Home</button>
</div>
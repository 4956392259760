<div class="container-fluid animated fadeIn">
    <div class="ml-5 pl-5 pb-4">
        <h2>{{band.band}}</h2>
    </div>
    <div class="d-flex row">
        <div class="pl-5 col-lg-6 text-center">
            
            <div id="carouselExampleInterval" class="carousel slide pt-2 " data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active" data-interval="3500">
                        <img src={{band.img[0]}} class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item" data-interval="3500">
                        <img src={{band.img[1]}} class="d-block w-100" alt="...">
                    </div>
                    <div class="carousel-item" data-interval="3500">
                        <img src={{band.img[2]}} class="d-block w-100" alt="...">
                    </div>
                </div>
                <a class="carousel-control-prev" href="#carouselExampleInterval" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleInterval" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
        <div id="info" class="d-inline-block col-lg-6">
            <div class="col-12 p-3 box-background">
                <span>{{band.bio}}</span>
            </div>

            <div *ngIf="band.band!=='MTFLabs'" class="col-lg-12 pt-5 icons" id="media" style="text-align-last: justify;" >
                <span class="pr-3">
                    <a href={{band.spotify}} target="_blank">
                        <mat-icon svgIcon="Spotify"></mat-icon>
                    </a>
                </span>

                &nbsp;
                <span class="pr-3">
                    <a href={{band.bandcamp}} target="_blank">
                        <mat-icon svgIcon="Bandcamp"></mat-icon>
                    </a>
                    &nbsp;
                </span>
                <span class="pr-3">
                    <a href={{band.instagram}} target="_blank">
                        <mat-icon svgIcon="Instagram"></mat-icon>
                    </a>
                    &nbsp;
                </span>
                <span *ngIf="band.band!=='Mayer-Sánchez'" class="">
                    <a href="{{band.video[0]}}" target="_blank">
                        <mat-icon svgIcon="Youtube"></mat-icon>
                    </a>
                </span>
            </div>
            <div *ngIf="band.band=='MTFLabs'" class="col-lg-12 pt-5 icons" id="media" style="text-align-last: justify;" >
                <span class="pr-3">
                    <a href={{band.bandcamp}} target="_blank">
                        <mat-icon svgIcon="Web"></mat-icon>
                    </a>
                </span>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="d-flex row no-wrap pl-3" [ngClass]="(band.band=='Mayer-Sánchez' || 'MTFLabs')?'justify-content-left':'justify-content-center'">
            <div *ngIf="band.band!=='MTFLabs'" class="justify-content-center d-inline-block p-5 col-lg-6 text-left" [ngClass]="(band.band=='Mayer-Sánchez')?'ml-5':'pl-0'">
                <h4>Songs</h4>
                <br>
                <div *ngIf="band.band=='Mayer-Sánchez'" class="pl-5">
                    <iframe style="border: 0; width: 100%; height: 42px; border-radius: 5px;"
                        src="https://bandcamp.com/EmbeddedPlayer/track=1639418983/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/"
                        seamless><a href="http://mayer-sanchez.bandcamp.com/track/otto">Otto by
                            Mayer-Sanchez</a></iframe>
                    <br>
                    <iframe style="border: 0; width: 100%; height: 42px; border-radius: 5px;"
                        src="https://bandcamp.com/EmbeddedPlayer/album=3007298758/size=small/bgcol=ffffff/linkcol=0687f5/track=3999863078/transparent=true/"
                        seamless><a href="http://mayer-sanchez.bandcamp.com/album/city-life">City Life by
                            Mayer-Sanchez</a></iframe>
                </div>
                <div *ngIf="band.band=='Unfollowers'">
                    <iframe style="border: 0; width: 100%; height: 42px; border-radius: 5px;" src="https://bandcamp.com/EmbeddedPlayer/track=2138707653/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless>
                        <a href="http://unfollowers.bandcamp.com/track/brigada-antidiversi-n">Brigada Antidiversión
                        </a></iframe>

                    <br>
                    <iframe style="border: 0; width: 100%; height: 42px; border-radius: 5px;" src="https://bandcamp.com/EmbeddedPlayer/album=1626912764/size=small/bgcol=ffffff/linkcol=0687f5/track=2757141823/transparent=true/" seamless>
                        <a href="http://unfollowers.bandcamp.com/album/demo-1">Ningún Espejo
                    </a></iframe>
                </div>
                <div *ngIf="band.band=='Vuelve Zinc'">
                    <iframe style="border: 0; width: 100%; height: 42px; border-radius: 5px;" src="https://bandcamp.com/EmbeddedPlayer/track=1956460795/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless>
                        <a href="http://vuelvezinc.bandcamp.com/track/cada-indecisi-n">
                        Cada Indecisión</a></iframe>
                    <br>
                    <iframe style="border: 0; width: 100%; height: 42px; border-radius: 5px;" src="https://bandcamp.com/EmbeddedPlayer/track=3771674366/size=small/bgcol=ffffff/linkcol=0687f5/transparent=true/" seamless>
                        <a href="http://vuelvezinc.bandcamp.com/track/la-parte-que-reconozco-de-los-dos-radio-edit">
                            La Parte que Reconozco de los Dos</a></iframe>
                </div>
            </div>
            <div *ngIf="band.band!=='Mayer-Sánchez'" class="justify-content-right d-inline-block pl-5 pt-5 col-lg-6 text-left">
                <h4>Videos</h4>
                <br>
                <div *ngIf="band.band=='Unfollowers'">
                    <iframe style="border-radius: 10px;" width="600" height="338" style="border-radius: 10px;" src="https://www.youtube-nocookie.com/embed/-TlAD3ftwqA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <br>
                    <iframe class="mt-3" width="600" height="335" style="border-radius: 10px;" src="https://www.youtube-nocookie.com/embed/WiQ9zBth5Rg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
               </div>
                <div *ngIf="band.band=='Vuelve Zinc'">
                    <iframe width="600" style="border-radius: 10px;" height="338" src="https://www.youtube-nocookie.com/embed/OCpvUnVVjbA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <br>
                    <iframe class="mt-3" width="600" style="border-radius: 10px;" height="338" src="https://www.youtube-nocookie.com/embed/fK1FeHEqUOc" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div *ngIf="band.band=='MTFLabs'">
                    <iframe src="https://player.vimeo.com/video/470627649" width="600" height="338" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                    <p><a href="https://vimeo.com/470627649">#MTFLabs Aveiro - High Tech Rustic</a> from <a href="https://vimeo.com/user4130512">Andr&eacute; Maia</a> on <a href="https://vimeo.com">Vimeo</a>.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="justify-content-end pt-5 text-right icons mr-5">
        <button class="btn btn-outline btn-light icons" [routerLink]="['/bands']" routerLinkActive="router-link-active">Back
            to Bands</button>
    </div>
</div>